import bodymovin from 'lottie-web'

$(document).on('turbo:load', function (e) {
  const animationElements = Array.prototype.slice.call(document.getElementsByClassName('js-animation-container'))

  animationElements.forEach(function (key) {
    import(
      `./animations/${key.dataset.animationVariant}.json`
    )
      .then(x => {
        bodymovin.loadAnimation({
          container: key,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: x.default
        })
      })
  })
})

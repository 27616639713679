import { Controller } from '@hotwired/stimulus'

const SUB_PRICES_SELECTOR = 'input[id^="variant_bundle_compositions_attributes_"][id$="_sub_price"]'
export default class extends Controller {
  updateCompositionBundlePrice (event) {
    const selectedOption = event.currentTarget.options[event.currentTarget.selectedIndex]
    const price = this.extractPrice(selectedOption.textContent)
    if (price > 0) {
      const input = event.currentTarget.closest('.js-nested-fields').querySelector(SUB_PRICES_SELECTOR)
      if (input) {
        input.value = price
      }
    }
    this.calculateTotalPrice()
  }

  extractPrice(optionText) {
    const priceMatch = optionText.match(/(\d+,\d{2})€ /)
    return priceMatch ? parseFloat(priceMatch[1].replace(',', '.')) : 0
  }

  calculateTotalPrice () {
    const variantPriceInput = document.getElementById('variant_price')
    if (variantPriceInput) {
      const bundlePrices = document.querySelectorAll(SUB_PRICES_SELECTOR)
      const totalBundlePrice = Array.from(bundlePrices).reduce((total, input) => total + parseFloat(input.value || 0), 0)
      const baseVariantPrice = parseFloat(variantPriceInput.dataset.baseVariantPrice)
      variantPriceInput.value = (totalBundlePrice + baseVariantPrice).toFixed(2)
    }
  }
}

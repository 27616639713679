import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['elements', 'elementQuantity', 'elementSelector', 'quantity', 'template']
  }

  initialize () {
    this.quantityTarget.focus()

    // Update the counters to populate the summary if supplier orders were
    // rendered server side
    this.update()
  }

  removeSupplierOrder (event) {
    event.preventDefault()

    this.element.parentNode.removeChild(this.element)

    this.update()
  }

  addElement (event) {
    event.preventDefault()

    const quantity = this.quantityTarget.value

    if (quantity === '') {
      return alert('Please enter a quantity')
    }

    const selectedElement = this.elementSelectorTarget.options[this.elementSelectorTarget.selectedIndex]

    if (this.existingRecords().includes(selectedElement.value)) {
      return alert('Element already added for Supplier')
    }

    // The supplier id is already set in the template
    const content = this.templateTarget.innerHTML
      .replace(/ELEMENT_ID/g, selectedElement.value)
      .replace(/ELEMENT/g, selectedElement.label)
      .replace(/QUANTITY/g, quantity)

    // Add the row above the add element row
    this.element.insertAdjacentHTML('beforeend', content)

    // Clear the quantity field and refocus
    this.quantityTarget.value = ''
    this.quantityTarget.focus()

    this.update()
  }

  existingRecords () {
    return this.data.get('existingRecords').split(',')
  }

  updateExistingRecords () {
    const ids = this.elementsTargets.map(function (target) {
      return target.dataset.referenceId
    })

    this.data.set('existingRecords', ids)
  }

  validElementQuantities () {
    // We only want elements with quantities > 0
    const validQuantities = []
    this.elementQuantityTargets.forEach(function (elementQuantity) {
      const value = parseInt(elementQuantity.value)
      if (value > 0) {
        validQuantities.push(value)
      }
    })
    return validQuantities
  }

  update () {
    this.updateExistingRecords()
    this.updateCounters()
  }

  updateCounters () {
    const quantities = this.validElementQuantities()
    const total = quantities.reduce(function (memo, elementQuantity) {
      return memo + elementQuantity
    }, 0)

    // We'll save the counters using .dataset rather than using Stimulus
    // data maps as we'll access them outside the controller elsewhere.
    this.element.dataset.elementsCount = quantities.length
    this.element.dataset.elementsTotal = total

    const supplierOrdersElement = document.querySelector('[data-controller="wms--demand--supplier-orders"]')

    // Make the parent controller update the summary
    this.application.getControllerForElementAndIdentifier(supplierOrdersElement, 'wms--demand--supplier-orders').update()
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  removeElement (event) {
    event.preventDefault()

    const parentController = 'wms--demand--supplier-order'
    // Fetch the parent controller before we remove the element
    const parentControllerElement = this.element.closest(`table[data-controller="${parentController}"]`)

    this.element.parentNode.removeChild(this.element)

    // Trigger an update on the parent
    this.application.getControllerForElementAndIdentifier(parentControllerElement, parentController).update()
  }
}

import { BaseController } from './base_controller.js'

// You will refer to this as `wms--demand--projection` due to namespacing.
export default class extends BaseController {
  saveProjection (event) {
    const target = event.target
    const date = target.dataset.date
    const elements = this.getElements('date', target.dataset.date)
    const dailyTotal = document.getElementById(this.element.id).querySelector(`th input[data-date="${date}"]`).value

    const projectionsFormData = {
      daily_total: dailyTotal,
      date: date,
      elements_and_quantities: [],
      workshop_id: this.data.get('workshopId')
    }

    this.updateLabelWhileSaving(target)

    elements.forEach((el, i) => {
      if (el.value === '') { return }
      projectionsFormData.elements_and_quantities.push({ element_id: el.dataset.elementId, quantity: el.value })
    })

    this.postRequest('/demand/projections', { projections_form: projectionsFormData })
  }
}

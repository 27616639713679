import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['quantityInput', 'submitButton', 'manyRadioButtonLabel', 'singleRadioButtonLabel', 'singleManyRadioButtonLabel', 'manyGroupRadioButtonLabel', 'workstationLink']
  }

  initialize () {
    this._singleLabelRadioText = this.data.get('singleLabelRadioText')
    this._manyLabelRadioText = this.data.get('manyLabelRadioText')
    this._singleManyLabelRadioText = this.data.get('singleManyLabelRadioText')
    this._manyGroupLabelRadioText = this.data.get('manyGroupLabelRadioText')
    this._workstationSet = (this.data.get('workstationSet') === 'true')
  }

  formSubmit (event) {
    event.preventDefault()

    const form = this.element.getElementsByTagName('form')[0]

    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'container_transfer_creation_form[new_containers]'
    input.value = true

    form.appendChild(input)

    form.submit()
  }

  quantityInputChange (event) {
    if (this._workstationSet) {
      if (event.target.value === '') {
        this.disableSubmitButton()
      } else {
        this.enableSubmitButton()
        this.updateRadioButtons(event.target.value)
      }
    } else {
      this.updateWorkstationLink(event.target.value)
    }
  }

  enableSubmitButton () {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('button__wrapper--disabled')
  }

  disableSubmitButton () {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('button__wrapper--disabled')
  }

  updateRadioButtons (value) {
    if (this.hasManyRadioButtonLabelTarget) {
      // This option may not exist for element transferer
      this.manyRadioButtonLabelTarget.innerText = this.updateLabelQuantity(this._manyLabelRadioText, value)
    }
    this.singleRadioButtonLabelTarget.innerText = this.updateLabelQuantity(this._singleLabelRadioText, value)
    this.singleManyRadioButtonLabelTarget.innerText = this.updateLabelQuantity(this._singleManyLabelRadioText, value)
    if (this.hasManyGroupRadioButtonLabelTarget) {
      // This option is behind the `parent_element_containers` flag so may not exist
      // This option may not exist for element transferer
      this.manyGroupRadioButtonLabelTarget.innerText = this.updateLabelQuantity(this._manyGroupLabelRadioText, value)
    }
  }

  updateLabelQuantity (label, quantity) {
    return label.replaceAll('QUANTITY', quantity)
  }

  updateWorkstationLink (value) {
    this.workstationLinkTarget.href = this.workstationLinkTarget.href.replace(/quantity_to_transfer=\d+/, 'quantity_to_transfer=' + value)
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['gmapIframe']
  }

  connect () {
    this.setGmapIframeUrl()
  }

  gmapIframeInput () {
    this.setGmapIframeUrl()
  }

  setGmapIframeUrl () {
    const iframeValue = this.gmapIframeTarget.value
    const matchedIframe = iframeValue.match(/src="(.*?)"/)

    if (matchedIframe) {
      this.gmapIframeTarget.value = matchedIframe[1]
    }
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['refundSelect']
  }

  refundSelectTargetConnected (element) {
    console.log('Connecting')
    if (this.choicesInstance) {
      console.log('Already initiated. Exiting.')
      return
    }

    import('choices.js')
      .then(({ default: Choices }) => {
        this.choicesInstance = new Choices(
          element,
          {
            itemSelectText: '',
            shouldSort: false,
            placeholder: true,
            placeholderValue: 'Pas de refund'
          }
        )
      })
      .catch((_error) => 'An error occurred while loading Choices')

    element.addEventListener('change', e => this.updateTotalRefund(e))
  }

  updateTotalRefund (_event) {
    const options = this.choicesInstance._presetChoices
    const selectedOptions = this.choicesInstance.getValue(true)
    const total = selectedOptions.reduce((acc, a) => acc + parseFloat(a), 0)
    const max = parseFloat(options[0].value)
    const remaining = max - total

    // The ... (spread) helps copy the option object values without modifying the original
    // The toFixed(2) helps fix the rounding error converting the number to string with correct decimals
    // Then we need to convert the string again to float to compare with the value
    const newOptions = options
      .filter(option => !selectedOptions.includes(option.value))
      .map(option => ({
        ...option,
        disabled: parseFloat(option.value) > parseFloat(remaining.toFixed(2))
      }))
    this.choicesInstance.setChoices(newOptions,
      'value',
      'label',
      true)

    this.updateTotalRefundText(this.element, total)
  }

  updateTotalRefundText (element, total) {
    const refundTotal = document.querySelector('.js-refund-total-aux')
    if (refundTotal) {
      refundTotal.remove()
    }
    if (total > 0) {
      element.closest('.choices').insertAdjacentHTML('beforebegin', `<div class='js-refund-total-aux'>Total refund: ${total.toFixed(2)}€</div>`)
    }
  }
}

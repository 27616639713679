import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['nameField', 'headingField', 'textField', 'iconField']
  }

  initialize () {
    this.iconSelectOptions = JSON.parse(this.element.dataset.iconSelectOptions)
    this.predefinedCharacteristics = JSON.parse(this.element.dataset.predefinedCharacteristics)
    this.templateSelect = this.element.closest('form').querySelector('[data-characteristicsTemplateOptions]')

    this.initializeIconSelect()
    this.prefillFromTemplate()
  }

  initializeIconSelect () {
    $(this.iconFieldTarget).select2({
      width: '100%',
      templateResult: (option) => {
        return this.iconSelectOptions[option.id]
      },
      templateSelection: (option) => {
        return this.iconSelectOptions[option.id]
      },
      escapeMarkup: function (m) {
        return m
      }
    })
  }

  prefillFromTemplate () {
    if (this.templateSelect.value !== '') {
      const predefinedValues = this.predefinedCharacteristics[this.templateSelect.value]

      this.nameFieldTarget.value = predefinedValues.name
      $(this.nameFieldTarget).trigger('change')

      this.headingFieldTarget.value = predefinedValues.heading
      $(this.headingFieldTarget).trigger('change')

      this.textFieldTarget.value = predefinedValues.text

      this.iconFieldTarget.value = predefinedValues.icon
      $(this.iconFieldTarget).trigger('change')
    }
  }
}

import './auto_importer'

// This is required to be able to use stimulus controller referenced by path and not directly registered on the
// stimulus application.
import { stimulusApp } from '../../../stimulus_app.js'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
let stimulus = stimulusApp();
const appContext = require.context('../../../../../app/javascript/controllers/', true, /_controller\.js$/)
stimulus.load(definitionsFromContext(appContext))

// Extracted from https://www.ducktypelabs.com/turbo-break-out-and-redirect/
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['elementSelect', 'quantity', 'template']
  }

  add (event) {
    event.preventDefault()

    const selectedElement = this.elementSelectTarget.options[this.elementSelectTarget.selectedIndex]
    const locationId = this.element.dataset.locationId

    const content = this.templateTarget.innerHTML
      .replace(/ELEMENT_ID/g, selectedElement.value)
      .replace(/ELEMENT_NAME/g, selectedElement.label)
      .replace(/LOCATION_ID/g, locationId)
      .replace(/QUANTITY/g, this.quantityTarget.value)

    this.element.insertAdjacentHTML('afterend', content)

    this.quantityTarget.value = ''
  }

  addWithEnter (event) {
    const enterKey = 13

    if (event.keyCode === enterKey || event.which === enterKey) {
      this.add(event)
    }
  }

  remove (event) {
    event.preventDefault()

    this.element.parentNode.removeChild(this.element)
  }
}

import { ApplicationController } from './../application_controller.js'

// You will refer to this as `wcs--dispatcher` due to namespacing.
export default class extends ApplicationController {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['dispatchButton', 'dispatchCount', 'dispatchList', 'dispatchItemTemplate', 'subOrderIds', 'dispatchErrorSound', 'overlay']
  }

  initialize () {
    this._boundCreateDispatchItem = this.createDispatchItem.bind(this)
  }

  connect () {
    if (!document.dispatcherListening) {
      document.addEventListener('scannerInput', this._boundCreateDispatchItem)
      document.dispatcherListening = true
    }
  }

  disconnect () {
    document.removeEventListener('scannerInput', this._boundCreateDispatchItem)
    document.dispatcherListening = undefined
  }

  alert (message) {
    this.dispatchErrorSoundTarget.play()

    // We need to wait to show the alert, otherwise the sound will play after
    setTimeout(() => {
      alert(message)
      this.setProcessing(false)
    }, 250)
  }

  appendDispatchItem (identifier) {
    const newDispatchItem = document.importNode(this.dispatchItemTemplateTarget.content, true)

    newDispatchItem.querySelector('li.dispatcherScanForm__dispatchItem').textContent = identifier

    this.dispatchListTarget.appendChild(newDispatchItem)
    this.updateCount()

    // Scroll to bottom of list
    this.dispatchListTarget.scrollTop = this.dispatchListTarget.scrollHeight
  }

  appendItem (identifier, subOrderIds) {
    this.appendDispatchItem(identifier)
    this.updateSubOrderIds(subOrderIds)
  }

  createDispatchItem (event) {
    if (this._processing) {
      return
    }

    this.setProcessing(true)
    this.subOrderRequest(event.detail.barcode)
      .then(response => {
        if (!response.ok) {
          throw Error
        }
        return response
      })
      .then(response => {
        response.json().then(data => {
          if (data.errors === undefined) {
            this.appendItem(data.sub_order.identifier, data.sub_order_ids)
            this.setProcessing(false)
          } else {
            this.alert(data.errors)
          }
        })
      })
      .catch(() => this.alert(this.data.get('generalErrorMessage')))
  }

  setDispatching () {
    this._processing = true
    this.dispatchButtonTarget.disabled = true
    this.dispatchButtonTarget.classList.add('button__wrapper--disabled')
    this.overlayTarget.classList.add('dispatcherScanForm__overlay--visible',
      'dispatcherScanForm__overlay--dispatching')
  }

  setProcessing (state) {
    if (state) {
      this._processing = true
      this.overlayTarget.classList.add('dispatcherScanForm__overlay--visible')
    } else {
      this._processing = false
      this.overlayTarget.classList.remove('dispatcherScanForm__overlay--visible')
    }
  }

  subOrderRequest (barcode) {
    return fetch(this.data.get('itemUrl'), {
      body: JSON.stringify({
        existing_sub_order_ids: this.subOrderIdsTarget.value,
        scanned_package_barcode: barcode
      }),
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getMetaValue('csrf-token')
      }
    })
  }

  updateSubOrderIds (subOrderIds) {
    this.subOrderIdsTarget.value = subOrderIds
  }

  updateCount () {
    const dispatchCount = this.dispatchListTarget.querySelectorAll('li').length
    this.dispatchCountTarget.textContent = dispatchCount

    if (dispatchCount > 0) {
      this.dispatchButtonTarget.disabled = false
      this.dispatchButtonTarget.classList.remove('button__wrapper--disabled')
    }
  }
}

import { ApplicationController } from './../../application_controller.js'

// Base controller for the demand controllers
export class BaseController extends ApplicationController {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return []
  }

  calculatePercentageValue (total, percentage) {
    return Math.round((total * percentage) / 100)
  }

  camelCaseToDash (string) {
    return string.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
  }

  changeTotal (event) {
    this.updateValues(event.target, 'date', 'elementId')
  }

  getElements (selector, selectorValue) {
    const elementsSelector = `td input[data-${selector}='${selectorValue}']`
    return document.getElementById(this.element.id).querySelectorAll(elementsSelector)
  }

  postRequest (url, data) {
    fetch(url, {
      body: JSON.stringify(data),
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getMetaValue('csrf-token')
      }
    })
      .then(response => {
        if (!response.ok) {
          throw Error
        }
        return response
      })
      .then(response => {
        location.reload()
      })
      .catch(() => alert('Error saving'))
  }

  updateLabel (element, label) {
    element.innerText = label
  }

  updateLabelWhileSaving (element) {
    this.updateLabel(element, '...')
  }

  updateValues (input, inputIdentifier, muiltiplerIdentifier) {
    const inputValue = input.value
    const elements = this.getElements(this.camelCaseToDash(inputIdentifier), input.dataset[inputIdentifier])

    elements.forEach((el, i) => {
      const muiltiplerSelectorValue = el.dataset[muiltiplerIdentifier]
      const muiltiplerSelector = `th[data-${this.camelCaseToDash(muiltiplerIdentifier)}='${muiltiplerSelectorValue}']`
      const muiltiplerValue = document.getElementById(this.element.id).querySelector(muiltiplerSelector).dataset.value
      el.value = this.calculatePercentageValue(inputValue, muiltiplerValue)
    })
  }
}

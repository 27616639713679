import { Application } from "@hotwired/stimulus"

export function stimulusApp() {
  let app;
  if (window.Stimulus == undefined) {
    app = Application.start()
    // Configure Stimulus development experience
    app.debug = false
    window.Stimulus = app
  } else {
    app = window.Stimulus
  }
  return app;
}

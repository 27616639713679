import { Controller } from '@hotwired/stimulus'
import MicroModal from 'micromodal'
import moment from 'moment-timezone-with-data-2010-2020'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['generationElement', 'degenerationElement']
  }

  // Gets call every time a <tr> element is clicked on Demand Overview table
  setModalValues (e) {
    const rowData = e.currentTarget.dataset

    if (rowData.createable === 'true') {
      const targetData = e.target.dataset
      const momentCreateBy = moment(targetData.createBy)

      document.querySelector('#demand-form-modal .modal__title').textContent = rowData.elementName
      // Update the demand generation element id selector
      this.generationChoices.setChoices([{ id: rowData.elementId, name: rowData.elementName }], 'id', 'name', true)
      this.generationChoices.setChoiceByValue(rowData.elementId)
      this.generationChoices.clearInput()
      $('#demand-form-modal #generation_form_create_by').data('daterangepicker').setStartDate(momentCreateBy)
      $('#demand-form-modal #generation_form_create_by').data('daterangepicker').setEndDate(momentCreateBy)
      // Update the actual value of the daterangepicker input
      $('#demand-form-modal #generation_form_create_by').data('daterangepicker').callback(momentCreateBy, momentCreateBy)

      $('#demand-form-modal #degeneration_form_quantity').val(targetData.maxDegenQuantity)
      // Update the demand degeneration element id selector
      this.degenerationChoices.setChoices([{ id: rowData.elementId, name: rowData.elementName }], 'id', 'name', true)
      this.degenerationChoices.setChoiceByValue(rowData.elementId)
      this.degenerationChoices.clearInput()
      $('#demand-form-modal #degeneration_form_date').val(momentCreateBy)
      $('#demand-form-modal #degen-date-str').html(momentCreateBy.format('MMMM Do, YYYY'))

      MicroModal.show('demand-form-modal', { awaitCloseAnimation: true })
    }
  }

  generationElementTargetConnected (element) {
    if (this.generationChoices) {
      return
    }
    import('choices.js')
      .then(({ default: Choices }) => {
        this.generationChoices = new Choices(
          element,
          {
            itemSelectText: '',
            searchResultLimit: 20
          }
        )

        if (element.dataset.remoteSearchPath) {
          element.addEventListener('search', async (e) => {
            const query = e.detail.value
            let searchPath = element.dataset.remoteSearchPath
            if (searchPath.includes('?')) {
              searchPath += '&q=' + query
            } else {
              searchPath += '?q=' + query
            }
            const data = await fetch(searchPath).then(res => {
              return res.json()
            })

            this.generationChoices.setChoices(data, 'id', 'name', true)
          })
        }
      })
      .catch((_error) => 'An error occurred while loading Choices')
  }

  degenerationElementTargetConnected (element) {
    if (this.degenerationChoices) {
      return
    }
    import('choices.js')
      .then(({ default: Choices }) => {
        this.degenerationChoices = new Choices(
          element,
          {
            itemSelectText: '',
            searchResultLimit: 20
          }
        )

        if (element.dataset.remoteSearchPath) {
          element.addEventListener('search', async (e) => {
            const query = e.detail.value
            let searchPath = element.dataset.remoteSearchPath
            if (searchPath.includes('?')) {
              searchPath += '&q=' + query
            } else {
              searchPath += '?q=' + query
            }
            const data = await fetch(searchPath).then(res => {
              return res.json()
            })

            this.degenerationChoices.setChoices(data, 'id', 'name', true)
          })
        }
      })
      .catch((_error) => 'An error occurred while loading Choices')
  }
}

import { Controller } from '@hotwired/stimulus'

/*
  Remember to 'connect' the controller in the form tag:
    data: { controller: 'erp--color-selector' }
*/

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return []
  }
  
  setColor (e) {
    e.preventDefault()
    const element = $(e.target)
  
    if (element.data('selector') === undefined)
      $('.js-colorpicker-component').val(element.data('colorpicker-color'))
    else
      $(element.data('selector')).val(element.data('colorpicker-color'))
  }

}

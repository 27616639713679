import { ApplicationController } from './../application_controller.js'

export default class extends ApplicationController {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['scanErrorSound']
  }

  connect () {
    if (this.element.dataset.reachedExpectedQuantityMessage) {
      this.showMessage(this.element.dataset.reachedExpectedQuantityMessage)
    }
  }

  showMessage (message) {
    this.scanErrorSoundTarget.play()

    // We need to wait to show the alert, otherwise the sound will play after
    setTimeout(() => {
      alert(message)
    }, 500)
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['locationSelect', 'addLocation', 'template', 'uploadButton', 'submitButton']
  }

  addLocation (event) {
    event.preventDefault()

    const selectedLocation = this.locationSelectTarget.options[this.locationSelectTarget.selectedIndex]

    const content = this.templateTarget.innerHTML
      .replace(/LOCATION_NAME/g, selectedLocation.label)
      .replace(/LOCATION_ID/g, selectedLocation.value)

    this.addLocationTarget.insertAdjacentHTML('beforebegin', content)
  }

  checkForUploadFile (event) {
    if (event.target.value !== '') {
      const disabledClass = 'button__wrapper--disabled'
      this.uploadButtonTarget.disabled = false
      this.uploadButtonTarget.classList.remove(disabledClass)
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add(disabledClass)
    }
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['quantityInput', 'submitButton']
  }

  selectQuantity (event) {
    this.quantityInputTarget.value = event.target.dataset.value
    this.enableSubmitButton(event.target.dataset.value)
  }

  quantityInputChange (event) {
    if (event.target.value === '') {
      this.disableSubmitButton()
    } else {
      this.enableSubmitButton(event.target.value)
      this.updateConfirmationMessage(event.target.value)
    }
  }

  enableSubmitButton (quantity) {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('button__wrapper--disabled')
    this.submitButtonTarget.innerHTML = `Package ${quantity}`
  }

  disableSubmitButton () {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('button__wrapper--disabled')
    this.submitButtonTarget.innerHTML = 'Package'
  }

  updateConfirmationMessage (quantity) {
    const message = this.data.get('confirmationMessage').replace('QUANTITY', quantity)

    this.submitButtonTarget.setAttribute('data-confirm', message)
  }

  submitQuantity(event) {
    const confirmMessage = event.target.dataset.confirm
    if (confirm(confirmMessage)) {
      return
    } else {
      event.preventDefault()
    }
  }
}

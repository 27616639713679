import { BaseController } from './base_controller.js'

// You will refer to this as `wms--demand--bulkdemand` due to namespacing.
export default class extends BaseController {
  matchDemand (event) {
    const target = event.target
    const date = target.dataset.date
    const workshopId = target.dataset.workshopId
    const elements = this.getElements('date', target.dataset.date)

    const bulkDemandForm = {
      create_by: date,
      workshop_id: workshopId,
      elements_and_quantities: []
    }

    this.updateLabelWhileSaving(target)

    elements.forEach((el, i) => {
      if (el.value === '') { return }
      bulkDemandForm.elements_and_quantities.push({ element_id: el.dataset.elementId, quantity: el.value })
    })

    this.postRequest('/demand/bulk_demand', { bulk_demand_form: bulkDemandForm })
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
    */
  static get targets () {
    return ['tabBarWrapper']
  }

  enable (event) {
    if (this.tabBarWrapperTarget) {
      this.tabBarWrapperTarget.classList.remove('packager_scans__tab-bar-wrapper--disabled')
    }
  }
}

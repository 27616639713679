import { Controller } from '@hotwired/stimulus'

/*
  A way to work with nested forms for associations.
  Works in conjuntion with iso/internal/internal_base/app/helpers/nested_fields_form_helper.rb
  See https://guides.rubyonrails.org/form_helpers.html#nested-forms

  Remember to 'connect' the controller in the form tag:
    data: { controller: 'erp--nested-fields' }
*/
export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return []
  }

  addNestedFields (e) {
    e.preventDefault()

    // e.target is the clicked button parentElement is the link we want to display the field after.
    const link = e.target.parentElement

    if (link === null) {
      return
    }

    this.insertUniqId(link)

    e.target.closest('form').removeAttribute('js-form-init')
    document.dispatchEvent(new Event('js-dom-replaced'))
    document.dispatchEvent(new Event('js-remove-fields:init'))
  }

  removeNestedFields (e) {
    e.preventDefault()

    const fieldParent = e.target.closest('.js-nested-fields')

    if (!fieldParent) {
      return
    }

    const deleteField = fieldParent.querySelector('input[type="hidden"]')
    if (deleteField) {
      deleteField.value = 1
      fieldParent.style.display = 'none'
    }
    // Remove hidden js vlaidation field otherwise validation will triggered on those hidden fields
    // and block submitting action
    fieldParent.querySelectorAll('.js-field-validation').forEach(el => el.remove())
  }

  insertUniqId (link) {
    if (!link.dataset.id) {
      return
    }
    // Get the insert type from the link's dataset. Default to "afterEnd" if not specified.
    const insertType = link.dataset.insertType || 'afterEnd';
    // Save a unique timestamp to ensure the key of the associated array is unique.
    const time = new Date().getTime()
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    const linkId = link.dataset.id
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    const regexp = new RegExp(linkId, 'g')
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    const newFields = link.dataset.fields.replace(regexp, time)
    link.insertAdjacentHTML(insertType, newFields)
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
    */
  static get targets () {
    return ['submitButton', 'quantityInput', 'containerAmountInput', 'addToParentElementContainerInput']
  }

  updateSubmitButton (event) {
    if (event.target.value <= 0 || event.target.value === '') {
      this.disableSubmitButton()
    } else {
      if (this.containerAmountInputTarget.value <= 0) { return }
      if (this.quantityInputTarget.value <= 0) { return }
      this.enableSubmitButton()
    }
  }

  updateElementQuantity (event) {
    const labelQuantity = event.target.value
    const newValue = parseInt(this.data.get('amount-remaining-to-intake') / labelQuantity)

    if (newValue === 0 || isNaN(newValue)) { return this.disableSubmitButton() }

    this.containerAmountInputTarget.value = newValue
    this.enableSubmitButton()
  }

  toggleParentElementContainer (event) {
    if (event.target.checked) {
      this.containerAmountInputTarget.value = 1
      this.containerAmountInputTarget.readOnly = true
    } else {
      this.containerAmountInputTarget.readOnly = false
    }
  }

  enableSubmitButton () {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('button__wrapper--disabled')
  }

  disableSubmitButton () {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('button__wrapper--disabled')
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['addSupplierOrderTable', 'submitButton', 'summaryRows', 'summaryRowTemplate', 'supplier', 'supplierOrderCount', 'supplierOrders', 'template', 'uploadButton']
  }

  initialize () {
    // Initialize the existing records data
    this.data.set('existingRecords')
  }

  addSupplier (event) {
    event.preventDefault()

    const selectedSupplier = this.supplierTarget.options[this.supplierTarget.selectedIndex]

    if (this.existingRecords().includes(selectedSupplier.value)) {
      return alert('Supplier already added')
    }

    const content = this.templateTarget.innerHTML
      .replace(/SUPPLIER_ID/g, selectedSupplier.value)
      .replace(/SUPPLIER/g, selectedSupplier.label)
      .replace(/NEW_RECORD/g, new Date().getTime())

    // We want to add the row above the add supplier row
    this.addSupplierOrderTableTarget.insertAdjacentHTML('beforebegin', content)

    this.updateExistingRecords()
  }

  checkForUploadFile (event) {
    if (event.target.value !== '') {
      const disabledClass = 'button__wrapper--disabled'
      this.uploadButtonTarget.disabled = false
      this.uploadButtonTarget.classList.remove(disabledClass)
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add(disabledClass)
    }
  }

  existingRecords () {
    return this.data.get('existingRecords').split(',')
  }

  updateExistingRecords () {
    const ids = this.supplierOrdersTargets.map(function (target) {
      return target.dataset.referenceId
    })

    this.data.set('existingRecords', ids)
  }

  supplierOrdersWithElements () {
    // Don't include suppliers with no element quantities
    return this.supplierOrdersTargets.filter(function (supplier) {
      return parseInt(supplier.dataset.elementsCount) > 0
    })
  }

  update () {
    this.updateExistingRecords()
    this.updateSummary()
  }

  updateSummary (event) {
    const supplierOrders = this.supplierOrdersWithElements()

    this.supplierOrderCountTarget.textContent = supplierOrders.length
    this.updateSummaryRows(supplierOrders)
  }

  updateSummaryRows (supplierOrders) {
    // Clear the current summary
    this.summaryRowsTarget.innerHTML = ''

    // Add each supplier order summary
    supplierOrders.forEach(function (supplier) {
      const content = this.summaryRowTemplateTarget.innerHTML
        .replace(/SUPPLIER/g, supplier.dataset.supplierName)
        .replace(/ELEMENTS_COUNT/g, supplier.dataset.elementsCount)
        .replace(/ELEMENTS_TOTAL/g, supplier.dataset.elementsTotal)

      this.summaryRowsTarget.insertAdjacentHTML('beforeend', content)
    }, this)
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['buttonsToDisable']
  }

  disableButtons (event) {
    this.buttonsToDisableTargets.forEach(button => {
      button.classList.add('button__wrapper--disabled')
      // Don't disable itself, we use disable_with or the form component
      // submit disable for that.
      if (event.target === button) { return }
      button.disabled = true
    })
  }
}

import Trix from 'trix'

document.addEventListener('turbo:load', event => {
  document.querySelectorAll('trix-editor-placeholder').forEach(el => {
    const editorElement = document.createElement('trix-editor')
    editorElement.setAttribute('input', el.dataset.input)
    el.replaceWith(editorElement)
  })
})

// Do not accept files dropped into the editor area:
document.addEventListener('trix-file-accept', e => e.preventDefault())

const bergGreen = 'rgb(87, 128, 108)'
Trix.config.blockAttributes.default.tagName = 'p'
Trix.config.blockAttributes.heading1.tagName = 'h2'
Trix.config.blockAttributes.h3 = { tagName: 'h3' }
Trix.config.textAttributes.underline = {
  style: { textDecoration: 'underline' },
  parser: function (element) {
    return element.style.textDecoration === 'underline'
  },
  inheritable: true
}

Trix.config.textAttributes.bergGreen = {
  style: { color: bergGreen },
  parser: function (element) {
    return element.style.color === bergGreen
  },
  inheritable: true
}

Trix.config.textAttributes.bergBlack = {
  style: { color: 'black' },
  parser: function (element) {
    return element.style.color === 'black'
  },
  inheritable: true
}

Trix.config.toolbar.getDefaultHTML = function () {
  return `
    <trix-toolbar id="trix-toolbar" class="toolbar">
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="Bold" tabindex="-1">Bold</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="Italic" tabindex="-1">Italic</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="Strike" tabindex="-1">Strike</button>
          <button type="button" class="trix-button custom-button underline" data-trix-attribute="underline" title="underline" tabindex="-1">U</button>
          <button type="button" class="trix-button custom-button green" data-trix-attribute="bergGreen" title="Green color" tabindex="-1">G</button>
          <button type="button" class="trix-button custom-button black" data-trix-attribute="bergBlack" title="Black color" tabindex="-1">B</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="Link" tabindex="-1">Link</button>
        </span>

        <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="Bullets" tabindex="-1">Bullets</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="Numbers" tabindex="-1">Numbers</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="Heading" tabindex="-1">Heading</button>
          <button type="button" class="trix-button" data-trix-attribute="h3" title="H3" tabindex="-1">H3</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="Quote" tabindex="-1">Quote</button>
        </span>

        <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="Undo" tabindex="-1">Undo</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="Redo" tabindex="-1">Redo</button>
        </span>
      </div>
      <div class="trix-dialogs" data-trix-dialogs>
        <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div class="trix-dialog__link-fields">
            <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="Enter URL" aria-label="URL" required data-trix-input>
            <div class="trix-button-group">
              <input type="button" class="trix-button trix-button--dialog" value="Link" data-trix-method="setAttribute">
              <input type="button" class="trix-button trix-button--dialog" value="Unlink" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      </div>
    </trix-toolbar>
  `
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return []
  }

  dragstart (event) {
    console.log('dragstarted')
    event.dataTransfer.setData(
      'application/drag-key',
      event.target.getAttribute('data-id')
    )
    event.dataTransfer.effectAllowed = 'move'
  }

  dragover (event) {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
    return true
  }

  dragenter (event) {
    event.preventDefault()
    this.removeDragOverClasses(event)
    event.target.closest('tr').classList.add('drag-over')
  }

  dragleave (event) {
    event.preventDefault()
    // console.log('leaving') // eslint-disable-line
    // event.target.closest('tr').classList.remove('drag-over')
    // this.removeDragOverClasses(event)
  }

  removeDragOverClasses (event) {
    event.target.closest('tbody').querySelectorAll('.drag-over').forEach(i => i.classList.remove('drag-over'))
  }

  drop (event) {
    event.preventDefault()
    this.removeDragOverClasses(event)

    const data = event.dataTransfer.getData('application/drag-key')
    const dropTarget = event.target.closest('tr')
    const draggedItem = this.element.querySelector(`[data-id='${data}']`)
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem)

    let relPosition
    if (positionComparison & 4) {
      console.log('Inserting BEFORE') // eslint-disable-line
      dropTarget.insertAdjacentElement('beforebegin', draggedItem)
      relPosition = 'before'
    } else if (positionComparison & 2) {
      console.log('Inserting AFTER') // eslint-disable-line
      dropTarget.insertAdjacentElement('afterend', draggedItem)
      relPosition = 'after'
    }
    this.postNewPosition(draggedItem)
  }

  postNewPosition(draggedItem) {
    const frame = draggedItem.closest('turbo-frame')
    const postUrl = draggedItem.closest('[data-after-drop-url]').dataset.afterDropUrl
    const newOrder = Array.from(
      draggedItem
        .closest('[data-after-drop-url]')
        .querySelectorAll('tr[draggable]')
    ).map(x => x.dataset.id)
    frame.setAttribute('src', this.addNewOrderToUrl(postUrl, newOrder));
  }

  addNewOrderToUrl (url, newOrder) {
    const baseUrl = url.split('?')[0]
    const queryParams = url.split('?')[1]
    const queryString = new URLSearchParams(queryParams);
    queryString.set("order", newOrder);
    return baseUrl + "?" + queryString.toString()
  }

  dragend (event) {
    this.removeDragOverClasses(event)
  }
}

import { ApplicationController } from './../../application_controller.js'

export default class extends ApplicationController {
  /*
    We'll define the targets using a static get function until the espree
    eslint parser supports static props.
    Ref: https://github.com/airbnb/javascript/issues/589
  */
  static get targets () {
    return ['dataLink']
  }

  toggleSellability (event) {
    event.preventDefault()

    this.sendToggleRequest()
  }

  sendToggleRequest () {
    this.tableCell().classList.add('workshopSellableOverview__salesToggling')

    const controller = this
    fetch('/workshop_sellable_overview/toggle_sellability', {
      body:
        JSON.stringify({ element_id: this.elementId, disable_sellability_on: this.date, workshop_id: this.workshopId }),
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getMetaValue('csrf-token')
      }
    })
      .then(response => {
        controller.tableCell().classList.remove('workshopSellableOverview__salesToggling')
        if (!response.ok) {
          throw Error
        }
        controller.toggleHtmlStyling()
        return response
      })
      .catch((error) => {
        console.log(error)
        alert('Error saving')
      })
  }

  tableCell () {
    return this.dataLinkTarget.parentElement
  }

  toggleHtmlStyling () {
    this.tableCell().classList.toggle('workshopSellableOverview__salesDisabled')
  }

  get elementId () {
    return this.dataLinkTarget.dataset.elementId
  }

  get date () {
    return this.dataLinkTarget.dataset.date
  }

  get workshopId () {
    return this.dataLinkTarget.dataset.workshopId
  }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (document.getElementById('guarantee-article')) {
      this.toggleGuaranteeArticle()
    }

    if (document.getElementById('care-articles')) {
      this.toggleCareArticles()
    }
  }

  toggleGuaranteeArticle () {
    if (document.getElementById('product_use_default_guarantee_blog_article').checked) {
      document.getElementById('guarantee-article').hidden = true
    } else {
      document.getElementById('guarantee-article').hidden = false
    }
  }

  toggleCareArticles () {
    if (document.getElementById('product_use_default_care_blog_articles').checked) {
      document.getElementById('care-articles').hidden = true
    } else {
      document.getElementById('care-articles').hidden = false
    }
  }
}
